@use 'styles/config' as *;
@use "sass:math";

.cta {
  @include backgroundColor;

  &__segment {
    @include segment();

    position: relative;
  }

  &__container {
    @include container();
  }

  &__header {
    // text-align: center;
  }

  &__title {
    @include h2();
    margin: 0 0 4rem;

    // max-width: 17.5em;
    // margin: 0 auto 1.5em;
  }

  &__text {
    @include render-copy();

    margin: 0 0 4rem;
    // max-width: 50rem; // temp

    font-size: 1.8rem;
    font-weight: 300;

    :global(body.dark) & p {
      color: #f4f4f4;
    }

    line-height: math.div(30, 18);
  }

  &__button {
  }

  &__row {
    @include grid-row();
  }

  &__content {
    @include grid-col();

    @media (min-width: $min-840) {
      @include grid-col(5);
    }
  }

  &__figure {
    display: none;
    position: relative;

    @include grid-col(6);
    @include grid-offset-left(1);

    @media (min-width: $min-840) {
      display: flex;
      justify-content: center;
    }
  }

  &__image {
    position: absolute 0;

    top: -$segment-padding-mobile;
    bottom: -$segment-padding-mobile;

    background: center right url('/static/images/molecule.png') no-repeat;
    background-size: contain;

    @media (min-width: $min-720) {
      top: -$segment-padding;
      bottom: -$segment-padding;
    }
  }

  &__content {
    // display: flex;
    // justify-content: center;
  }
}
